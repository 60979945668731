import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import SignIn from '../components/Account/Login'

export default () => {
  return (
    <Layout>
      <SEO title="Login" />
      <SignIn />
    </Layout>
  )
}
