import { makeStyles } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: CONF.css.blue,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: CONF.css.blue,
  },
  fbButton: {
    backgroundColor: '#E6EAF4',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  signUpNow: {
    backgroundColor: CONF.css.grey,
  },
  fbTypo: {
    color: CONF.css.blue,
    fontWeight: 700,
    fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
  },
  ggButton: {
    backgroundColor: '#F5E8EA',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  ggTypo: {
    color: CONF.css.red,
    fontWeight: 700,
    fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
  },
  test: {
    left: '30px',
    // margin: theme.spacing(1),
  },
}))
