import React, { useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { navigate } from 'gatsby'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import CONF from '../../../config'
import { useStyles } from './style'
import Grid from '@material-ui/core/Grid'
import { AccountAction } from '../state'
import { LOCAL_STORAGE, ROUTES } from '../../../constant'
import { getLoginInfo, removeKey } from '../../../utils/localStorage'
import { LayoutState } from '../../Layout/state'
import { useTranslation } from 'react-i18next'
import { useState } from '@hookstate/core'
import { SNACKBAR_MESSAGE_LIST } from '../../../globals/variable'

export default function SignIn() {
  const classes = useStyles()

  // local state
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const { t, i18n } = useTranslation()
  const snackState = useState(LayoutState.snack)

  useEffect(() => {
    console.log(`====getLoginInfo()======${JSON.stringify(getLoginInfo())}`)
    removeKey(LOCAL_STORAGE.LOGIN_INFO)
    console.log(`====getLoginInfo()======${JSON.stringify(getLoginInfo())}`)
  }, [])

  // const responseFacebook = (response) => {
  //   console.log('Facebook: ', response)
  //   AccountAction.login(response.email, response.userID)().then(() => {
  //     const abc = getLoginInfo()
  //     console.log(`======abc======${JSON.stringify(abc)}`)
  //     if (abc != null) {
  //       snackState.set({ text: `${t('Hello')} ${abc.username}`, variant: 'success' })
  //     }
  //   })
  //   navigate(ROUTES.HOME)
  //   window.location.reload()
  // }

  // const responseGoogle = (response) => {
  //   if (response.profileObj == null) return
  //   console.log('Google: ', response.profileObj)
  //   const data = response.profileObj
  //   AccountAction.login(data.email, data.googleId)().then(() => {
  //     const abc = getLoginInfo()
  //     console.log(`======abc======${JSON.stringify(abc)}`)
  //     if (abc != null) {
  //       snackState.set({ text: `${t('Hello')} ${abc.username}`, variant: 'success' })
  //     }
  //   })
  //   navigate(ROUTES.HOME)
  // }

  const handleClickLogin = () => {
    AccountAction.login(email, password)().then(() => {
      const abc = getLoginInfo()
      console.log(`======abc======${JSON.stringify(abc)}`)
      if (abc != null) {
        // snackState.set({ text: `${t('Hello')} ${abc.username}`, variant: 'success' })
        // SNACKBAR_MESSAGE_LIST.push(`${t('Hello')} ${abc.username}`)
        SNACKBAR_MESSAGE_LIST.push({ text: `${t('Hello')} ${abc.username}`, variant: 'success' })
      }
    })
    navigate(ROUTES.HOME)
    setTimeout(function () {
      // navigate(ROUTES.HOME)
      window.location.reload()
    }, 1000)
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Login')}
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => {
              setEmail(event.target.value)
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => {
              setPassword(event.target.value)
            }}
          />
          {/*<div className={classes.test}>*/}
          {/*  <Grid container spacing={1} alignItems="flex-end">*/}
          {/*    <Grid item>*/}
          {/*      <AccountCircle />*/}
          {/*    </Grid>*/}
          {/*    <Grid item>*/}
          {/*      <TextField id="input-with-icon-grid" label="With a grid" />*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*</div>*/}
          {/*<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />*/}
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
            onClick={handleClickLogin}
          >
            {t('Login')}
          </Button>

          {/*<Link to="#">Forgot password?</Link>*/}
        </form>
      </div>
      {/*<FacebookLogin*/}
      {/*  appId={process.env.GATSBY_FACEBOOK_APP_ID}*/}
      {/*  callback={responseFacebook}*/}
      {/*  fields="name,email,picture"*/}
      {/*  textButton="Facebook"*/}
      {/*  render={(renderProps) => (*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      fullWidth*/}
      {/*      size="large"*/}
      {/*      className={classes.fbButton}*/}
      {/*      onClick={renderProps.onClick}*/}
      {/*      // startIcon={<FontAwesomeIcon icon={faFacebookF} size={'1x'} color={CONF.css.blue} />}*/}
      {/*    >*/}
      {/*      <Grid container spacing={1} alignItems="flex-end">*/}
      {/*        <Grid item xs={1}>*/}
      {/*          <FontAwesomeIcon icon={faFacebookF} size={'1x'} color={CONF.css.blue} />*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={11}>*/}
      {/*          <Typography className={classes.fbTypo} variant="body2" gutterBottom>*/}
      {/*            {t('Login with')} Facebook*/}
      {/*          </Typography>*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*/>*/}
      {/*<GoogleLogin*/}
      {/*  clientId={process.env.GATSBY_GOOGLE_APP_ID as string}*/}
      {/*  buttonText="Login"*/}
      {/*  onSuccess={responseGoogle}*/}
      {/*  onFailure={responseGoogle}*/}
      {/*  cookiePolicy={'single_host_origin'}*/}
      {/*  // isSignedIn={true}*/}
      {/*  render={(renderProps) => (*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      fullWidth*/}
      {/*      size="large"*/}
      {/*      className={classes.ggButton}*/}
      {/*      onClick={renderProps.onClick}*/}
      {/*      // startIcon={<FontAwesomeIcon icon={faGoogle} size={'1x'} color={CONF.css.red} />}*/}
      {/*    >*/}
      {/*      <Grid container spacing={1} alignItems="flex-end">*/}
      {/*        <Grid item xs={1}>*/}
      {/*          <FontAwesomeIcon icon={faGoogle} size={'1x'} color={CONF.css.red} />*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={11}>*/}
      {/*          <Typography className={classes.ggTypo} variant="body2" gutterBottom>*/}
      {/*            {t('Login with')} Google*/}
      {/*          </Typography>*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*/>*/}
      {/*<Button fullWidth onClick={() => navigate(ROUTES.REGISTER)}>*/}
      {/*  Forgot password?*/}
      {/*</Button>*/}
      <Button className={classes.signUpNow} fullWidth onClick={() => navigate(ROUTES.REGISTER)}>
        {t("Don't have an account? Sign Up")}
      </Button>
    </Container>
  )
}

// {name: "Duy Vinh", email: "duyvinh229148@gmail.com", picture: {…}, id: "1807192532768646", accessToken: "EAACvxCE3HPUBACAlvn5ojvWorzR50vmOSK5V7P4shlyNnOAkU…1RQSr1ZCIpeEY7XeYqBnZABldOJHWhMzbjxEEKHSyWjhQZDZD", …}
// accessToken: "EAACvxCE3HPUBACAlvn5ojvWorzR50vmOSK5V7P4shlyNnOAkURRnW4PRWGRL479Y9aor4E5njwogMttRwsxYBJ2Fvcs8OYuucgeJ8Nf6ZAro1MKTYxFjD6hZBD3C6h1e94cG5bwZBBpkWbRwt4mLNulQFZAa7fT1RQSr1ZCIpeEY7XeYqBnZABldOJHWhMzbjxEEKHSyWjhQZDZD"
// data_access_expiration_time: 1623739837
// email: "duyvinh229148@gmail.com"
// expiresIn: 4163
// graphDomain: "facebook"
// id: "1807192532768646"
// name: "Duy Vinh"
// picture:
//   data:
//     height: 50
// is_silhouette: false
// url: "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=1807192532768646&height=50&width=50&ext=1618555838&hash=AeSYoq-K8NSiaw2U84Y"
// width: 50
// __proto__: Object
// __proto__: Object
// signedRequest: "P5lNJsyyFcZb8NCCGuy0nnfyLs7h5YRkyEDSlpQAVnw.eyJ1c2VyX2lkIjoiMTgwNzE5MjUzMjc2ODY0NiIsImNvZGUiOiJBUURhTUVZOE9aNzZLdzRCejJBaXRLallWLXBwZWNtOC1Ia0VZdndUZEM3SWR6RlFuQ1o1T0F6VEZLMU9VRGZuUWVGR0RFWGIwYnB1YXdjUlloRGVRNWNtd01yd0lzZ0dwbmttRk4yZWNVUnpNc1FjeXQ2T3g1YXNuZEVYSDM2cmZ5WDdydkppNjNIX2hBRlpmY0tZZWZhV1JWb3RXcGRzMlQxWnV4R2prbEp6OE5kN1RpX2ZSWS1sS21FQktwS0oyN0dnZDBJelN2V3E4T19kTUYzdFF2MkRWcmJQdGlSQWE0VVNLaTQtQWZfUmZNZW9mWVl4UHQyS245NERBZ0tneVpRbmxmSXhzU1VhcXJYOWZ2dGZiRWlCaHVvaGh5V1ZBQ1hWdVEtU2xFUDkyZ0dMTTg2M1FPa0k5MVljNDFIOUFabVBNdmw2cjZ4R2xwd3pFaUJPZ1c4ayIsImFsZ29yaXRobSI6IkhNQUMtU0hBMjU2IiwiaXNzdWVkX2F0IjoxNjE1OTYzODM3fQ"
// userID: "1807192532768646"

// {googleId: "102514487585714365543", imageUrl: "https://lh3.googleusercontent.com/a-/AOh14Gji121L-bEj6049-Gs2WcbikwAi8sGJfaowGzKvfg=s96-c", email: "duyvinh229148@gmail.com", name: "Duy Vinh Đỗ", givenName: "Duy Vinh", …}
